/*
mobile and responsive rules;
min-width: 768px -->  tablets (portrait);
min-width: 992px -->  tablets (landscape) and small screens;
min-width: 1200px --> big screens;
*/

@media screen and (min-width: 400px) {
	.free-to-read {
		> div {
			a {
				&:first-child {
					img {
						width: auto;
					} 
				}
			}
		}
	}
}

@media screen and (min-width: 768px) {   
	h1, .sec-header-style {
        font-size: 200%;                
    } 
     
    .navbar {
        border-radius: 0px;
    }   

     #fixed-header {    	
     	position: relative;
        z-index: 100;
    	-webkit-box-shadow: 0px -1px 8px 10px rgba(40,40,40, 0.30);
    	-moz-box-shadow: 0px -1px 8px 10px rgba(40,40,40, 0.30);
    	box-shadow: 0px -1px 8px 10px rgba(40,40,40, 0.30);
    }

    #header {
        .wrapper {
            padding: $mobile-side-padding $mobile-side-padding 5px $mobile-side-padding;  
        }
        .logo {
            float: left;
            margin-left: 0;
             width: 450px;
			height: 60px;     
			margin-top: $mobile-side-padding/4;			
        }
        .social-media {
            display: block;
            float: right;
        }
        nav {
            float: right; 
            margin-right: -8px;     
            clear: right;      
            &.navbar {                
                margin-top: 10px;
                #navbar {
                	li {
                		padding: 0 8px;
                		a {
							border: 0;    
							text-transform: uppercase;
							padding: 0px;
							line-height: 30px;       
                    	}
                	}                    
                }
            }                  
        }
    } 
    #news-section {
        nav.navbar {        	
            .navbar-header {
                h2 {
                    display: none;
                }
            }            
            #news-navbar {
            	> ul {
            		> li {
            			&:after {
							content: '/';
							color: #fff;
							position: absolute;
							font-weight: bold;
							top: 0;
							right: 0;
							margin-top: 5px;
							margin-right: -4px;
							font-size: 20px;
							opacity: 0.5;						
						}
						&:last-child {
							&:after {
								content: none;
							}
						}
						> a {
							border-top: 0 !important ;
							padding: 0 8px;
							line-height: 30px;      
							font-size: 15px;
						}
            		}            			
            	}            	           
            }    
        }        
    }    

    #footer {	
		nav {					
			&.navbar {
				background-color: $titles-bg-color;						
				ul {
					padding: 5px 0 5px $mobile-side-padding;	
					li {
						padding: 0 $screen-side-padding 0 0;
					}		
				}
			}
		}
		.wrapper {
			padding: 30px $mobile-side-padding 50px $mobile-side-padding;
			.footer-list {
				float: right;
				ul {
					text-align: right;					
				}
			}
			.contact-address {
				text-align: left;
				p {
					span {

					}
					&:first-child {
						margin-top: 0;
					}		
				}				
			}
			.copyright {
				margin-top: 40px;
				p {
					&:first-child {
						float: right;
					}
					&:last-child {
						float: left;
					}
				}
			}
		}
	}	

	.articles {
		.row {
			@include grey-border-bottom;
			padding: $screen-side-padding 0;	
			&:first-child {
				padding-top: $mobile-side-padding;
			}							
			&:last-child {
				@include grey-border-bottom;
			}
			.article {
				padding: 0;
			}				
		}
	}

	.article-page {
		.article {
			padding-top: $mobile-side-padding !important;
		}
		.article-page-first {
			padding-top: 0 !important;
		}
	}

	.article-img {
		max-width: 600px;
	}


	.main-article {
		.article-img-box {
			float: right;
			width: 52.5%;	
			padding-left: $screen-side-padding*2;		
		}

		h1 {
			margin-top: 0;
			padding-bottom: 8px;
			border-bottom: 1px solid $articles-header-color;
		}		
	}

	.double-cols {
		position: relative;
		.col-sm-6 {
			&:first-child {
				padding-right: $screen-side-padding;				
			}
			&:last-child {
				padding-left: $screen-side-padding;		
			}
		}		
	}

	.color-separator {
		&:before {
			content: '';
			display: block; 
			width: 1px;			
			position: absolute;
			top: 0;
			margin-top: $screen-side-padding;
			bottom: 0;
			margin-bottom: $screen-side-padding;
			right: 50%;
			background-color: $articles-header-color;
		}
	}

	.color-separator-first-row {
	    &:before {
	        margin-top: $mobile-side-padding;
	    }
	}

	.main-right-col {
		.articles {
			.row {
				&:first-child {
					padding-top: $screen-side-padding;
				}
			}
		}		
	}

	.tiled-articles {
		.article {
			.article-tile {
				display: block;
				float: right;	
				margin-top: $screen-side-padding;
				margin-left: $mobile-side-padding;			
			}				
		}
	}

	.classes {
		.row {
			.article {
				border-bottom: 0;
			}			
		}
	}

	
	.top-stories {
		.row {
			padding: 0 !important;
		}
		.tiled-articles {
			&:last-child {
				.col-sm-6 {					
					.article {
						border-bottom: 0;
					}
				}
			}
		}
		.row {
			.col-sm-6 {
				&:first-child {
					padding-right: $mobile-side-padding;
				}
				&:last-child {
					padding-left: $mobile-side-padding;
				}
			}
		}
	}			

	.top-page-header {	
		margin-bottom: $mobile-side-padding;		
	}	

	.form-inline {	
		margin-top: 10px;
		label {
			font-size: 88%;
			margin: 0 0 0 15px;	
			height: 20px;					
			text-transform: uppercase;
			color: $articles-header-color;
		}
		.form-control {
			margin-top: 0;
		}
		button {
			margin-top: 0;
		}	
		.cornered {
			display: inline-block;
		}
	}

	.company-search {
		margin-top: 0;
	}

	.companies-list {
		&.lg {
			a {							
				font-size: 150%;
				&:after {
					font-size: 30px;
					margin-top: -17px;
				}
				span {
				    font-size: 80%;
				}
			}
		}
	}	

	.double-companies-list {		
		padding-bottom: $screen-side-padding;		
		> div {
			&:last-child {
				.companies-list {
					margin-top: $mobile-side-padding;
					li { 
						&:first-child {
							border-top: 1px solid $titles-bg-color;
						}
					}
				}
			}
		} 
	}

	.company-page {
		.company-info-tiles {
			> div {
				> a {
					float: right;

				}
				h2 {
					margin-top: 0;
				}
			}
		}		
	}

	.breadcrumb-position {
		position: relative;
		.breadcrumb {
			position: absolute;
			bottom: 0;
			margin-bottom: 0;
		}
	}

	.main-left-col {
		.free-to-read {
			float: left;
			width: 47%;
			+ *{
				clear: left;
			}
		}
		.top-stories {
			display: block;
			> a {
				&:first-child {
					h4 {
						padding-left: 15px;
					}					
				}
			}
		}
	}	

	.read-comments {
	    margin-bottom: 10px;
	}

	.extra-padding {
		padding-top: $screen-side-padding !important;
	}

	.floated-form {
		margin-top: 0;	
		width: 60%;
		.cornered {
			//border-right: 2px solid $page-content-bg-color;
			box-sizing: content-box;
			input {
				width: 100%;
			}
		}
		.cornered {
			width: 46%;
		}
		.input-group {
			width: 50%;
			float: right;
		}
	}

	.floated-margin-fix {
		.form-inline {
			margin-top: 1px;
		}
	}

	.bulletin {
		form {
			.col-sm-6 {
				padding-right: $screen-side-padding;
				& + .col-sm-6 {
					padding-right: 0;
					padding-left: $screen-side-padding;
				}
			}
		}
	}

	/*iframe {
		height: 500px !important;
	}*/

	.text-box {
		+ .text-box {
			padding-top: 20px;
		}		
		.col-sm-9 {
			padding-right: $screen-side-padding;
		}
	}

	.e-learning {
		> h1,h2 {			
			&:first-child {
				margin-right: 25%;
			}
		}
	}

	.advertising {
		img {
			display: block !important;
			float: right;
			margin-left: 20px;
		}
	}

	#download-app {	
		img {
			display: block;
			border-radius: 0 0 10px 10px;
		}	
	}	

	.features {		
		li {
			font-size: 120%;
		}	
	}	

	#form-ctrl-buttons {		
		text-align: left;		
		button {			
			width: 165px;			
			font-size: 125%;					
			margin-right: 6px;	
		}
	}	
	
}

@media screen and (min-width: 992px) {
    h1, .sec-header-style {
        font-size: 220%;     
        line-height: 110%;   
    }       

     #fixed-header {
    	position: fixed;
    	width: 100%;
    	top: 0;    
    	z-index: 100;	
    }

    .col-md-9 {
    	padding-right: $mobile-side-padding;
    }

    .page-content {
    	padding: 30px 20px 30px;    	
		>.container {
			position: relative;
			&:before {
				content: '';
				display: block !important;
				width: 1px;
				height: 100%;     		
				background-color: $articles-header-color;
				position: absolute;
				right: 0;
				margin-right: 320px;
				top: 0;

			}
		}
	}


    .col-md-3 {
    	padding-left: $mobile-side-padding;
    }

    #header {
    	.logo {
    		margin-top: 0;
    	}
        .wrapper {
            padding: 8px $mobile-side-padding; 
        }
        .social-media {
        	margin-top: $mobile-side-padding*1.5;
        }
        nav {
            margin-right: $mobile-side-padding;
            clear: none;    
            &.navbar {
                margin-top: $mobile-side-padding*1.5;
                #navbar {

					a {
						&:hover {
							color: $titles-bg-color;
						}
					}
				}
            }        
        }        
    }
        
    #news-section {
        nav.navbar { 
            #news-navbar {
				@include navbar-cornered(40px,$navbar-hover-color); 
				> ul {
					> li {
						&:after {
							margin-top: 10px;
							margin-right: -1px;						
						}
						&:hover {
							& > .dropdown-div {
								opacity: 1;						
								max-height: 1000px;		
								z-index: -1;									
								-webkit-transition: opacity 0.5s, max-height 1s; /* Safari */ 
    							transition: opacity 0.5s, max-height 1s;											
							}
							&:after {
								display: none;						
							}
						}
					} 	
				}     
            }
        }
    }
    .page-content {
    	margin-top: 76px;
    }    
	
	.title-block {			
		padding: 8px 15px;	
		* {
			font-size: 125%;		
		}	
	}

	.article-img {
		&:hover {
			> div {
				p {
					opacity: 1;
					bottom: 0;
				}				
			}
		}
		> div {
		    div {
		        display: block;
		    }
		}
	}

	.main-right-col {
		padding-top: 0;
		padding-left: 20px;		
		width: 320px;	
		float: right;				
		.search-bar {
			display: block;
			padding-bottom: $mobile-side-padding;
		}
		.top-stories {
			.tiled-articles {
				&:last-child {
					.col-sm-6 {					
						&:first-child {
							.article {
								border-bottom: 1px solid $articles-header-color;
							}
						}
					}
				}
			}
			.row {
				.col-sm-6 {
					&:first-child {
						padding-right: 0;
					}
					&:last-child {
						padding-left: 0;
					}
				}
			}
		}			
	}	

	.main-left-col {
		width: 66.3%;
		padding-right: 20px;
		.search-bar {
			display: none;
		}
		> div {
			&:last-child {				
				border-bottom: 0;
			}
		}			
	} 

	.top-page-header {			
		h1 {
			font-size: 175% !important;
		}			
	}	

	.cornered-header {
		font-size: 175%;
	}

	.main-right-col {		
		.articles {
			.row {
				.col-sm-6 {
					&:first-child { 
						padding: 0;
					}
					&:last-child { 
						padding: 0;
					}
				}
				.article {
					.article-img-box {
						.link-title {
							padding: $mobile-side-padding 15px;
							h3 {
								font-size: 88%;
							}
						}						
					}
					h2 {
						margin-top: $mobile-side-padding;
						font-size: 150%;
					}
					padding: $screen-side-padding 0;
					@include grey-border-bottom;
				}
				padding: 0;
				border-bottom: 0;
				&:first-child {
					padding: 0;
					.col-sm-6 {
						&:first-child {
							.article {
								padding-top: $screen-side-padding;	
							}
						}
					}
				}
			}
		}
		.color-separator {
			&:before {				
				display: none; 				
			}
		}
		.article-img {
			> div {
				p {
					font-size: 88%;
				}
			}
		}
	}
	
	.list-page-header {
	    padding-top: 0;
	}

	.articles {
	    .row {
	        &:first-child {
	            padding-top: 0;
	        }
	    }
	}

	.color-separator-first-row {
	    &:before {
	        margin-top: 0;
	    }
	}	

	.dropdown-div {
		.col-md-9 {
			width: 66.3%;
		}
		.col-md-3 {
			float: right;
			width: 320px;
			padding-left: 20px;
		}
		.date-comment {
			padding-top: 10px;
			clear: both;			
		}
	}

	.bottom-link {
		width: 65.5% !important;
	}	

	.advertising {
		img {
			
		}
	}

	.advertising {
		.text-box {
			& > img {
				max-width: 180px;
			}
		}		
	}
}

@media screen and (min-width: 1024px) {
	.main-left-col, .dropdown-div .col-md-9 {
		width: 67.3%;
	}

	.bottom-link {
		width: 64.7% !important;
	}
}

@media screen and (min-width: 1080px) {
	.main-left-col, .dropdown-div .col-md-9 {
		width: 69.1%;
	}

	.dropdown-div {
		.date-comment {
			padding-top: 0px;
			clear: none;			
		}
	}

	.bottom-link {
		width: 64.3% !important;
	}
}

@media screen and (min-width: 1140px) {
	.main-left-col, .dropdown-div .col-md-9 {
		width: 70.8%;
	}

	.bottom-link {
		width: 63.1% !important;
	}
}

@media screen and (min-width: 1200px) {
	h1, .sec-header-style {
        font-size: 250%;            
    }

    h2 {
		&.sec-color {
			font-size: 200% !important;
		}
	}

	.col-md-9 {
    	padding-right: $screen-side-padding;    	
    }

    .col-md-3 {
    	padding-left: $screen-side-padding;
    }

    #header {
        .wrapper {
            padding-left: 0;
            padding-right: $screen-side-padding; 
        }
    }
    #news-section {
        nav.navbar { 
            #news-navbar {
				@include navbar-cornered(40px,$navbar-hover-color);          			
				> ul {
					> li {
						> a {
							font-size: 112%;
						}
						&:first-child {
							> a {
								padding-left: $screen-side-padding;
							}
						
						}
						.dropdown-div {    	
							.wrapper {								
								padding: $screen-side-padding;
								position:relative;    
								.row {
									.bottom-link {
										width: 730px !important;
									}    
								}
								.tiled-articles {
									.article {
										h2 {
											font-size: 150%;
										}
									}
								}    											
							}
            			}
					}
				}		
            }
        }
    }

	.page-content {
    	padding: 40px $screen-side-padding 30px;      	
	}	

	#footer {	
		nav {		
			&.navbar {
				background-color: $titles-bg-color;			
				ul {
					padding: 5px 0px 5px $screen-side-padding ;		
					li {
						padding: 0 $screen-side-padding*2.1 0 0;
						&:after {
							margin-right: 17px;
						}
						&:last-child {
							padding-right: $screen-side-padding;
						}
					}		
				}
			}
		}
		.wrapper {
			padding: 30px $screen-side-padding 60px $screen-side-padding;			
		}
	}

	.double-cols {
		.col-sm-6 {
			&:first-child {
				padding-right: $screen-side-padding;				
			}
			&:last-child {
				padding-left: $screen-side-padding;		
			}
		}			
	}	

	.articles {
		.row {
			padding: $screen-side-padding 0;					
			&:last-child {
				border-bottom: 4px solid $article-separator;
			}
		}
	}

	.main-right-col {	
		.articles {
			.row {				
				&:last-child {
					border-bottom: 0;
				}
			}
		}
	}	

	.article-img {
		max-width: 405px;
	}

	.tiled-articles {		
		.article {			
			.article-tile {				
				height: 100px;
				width: 140px;					
			}			
		}
	}

	.top-page-header {	
		margin-bottom: $screen-side-padding;
		left: $screen-side-padding*(-1);	
		h1 {
			font-size: 200%;
		}			
	}	

	.cornered-header {
		font-size: 200% !important;
	}

	.cornered-button {
		margin: $screen-side-padding 0;
	}

	.cornered-button-lg {
		padding-left: $mobile-side-padding;	
		h3 {			
			font-size: 100%;
			margin-left: 15px;
			font-size: 120%;
		}		
	}

	.directory {
		.grey-border {
			.floated-form {
				padding-bottom: $mobile-side-padding;
			}
		}
	}

	.breadcrumb-position {		
		.breadcrumb {			
			bottom: 10px;
			margin-bottom: 0;
		}
	}

	.main-left-col {
		width: 840px;
		.free-to-read {			
			width: 380px;
			+ *{
				clear: left;
			}
		}
	}	

	.free-to-read {
		.cornered-button {
			padding-left: 15px;
			h3 {
				margin-left: 10px;
				font-size: 125%;				
			}
		}
	}

	#download-app {	
		h3 {
			margin-top: 50px;		
		}	
	}	

	.subscribe-buttons-fix > img {
		max-width: 35%;
	}

	.dropdown-div {
		.col-md-9 {
			width: 840px;
		}		
	}

	.advertising {
		.text-box {
			& > img {
				max-width: 400px;
			}
		}		
	}
}
