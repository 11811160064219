@import url(http://fonts.googleapis.com/css?family=Roboto:300,300italic,400,400italic,500,700,700italic%7CRoboto+Condensed:300,400,700,300italic,400italic,700italic);
@import "reset";
@import "../../bower/bootstrap-sass/assets/stylesheets/bootstrap-sprockets";
@import "../../bower/bootstrap-sass/assets/stylesheets/bootstrap";
@import "../../bower/font-awesome/scss/font-awesome";
@import "mixins";

/*START Main Rules*/

html {
  height: 100%;
}
body {
  height: 100%;
  margin: 0;
  font-size: $text-font-size;
  line-height: $text-line-height;
  color: $main-text-color;
  @include linear-background-to-bottom($body-background-color-shadow, 1, 0, $body-background-color, 1, 50%);
  font-family: $main-font-family;
  -webkit-font-smoothing: antialiased;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

p {
  font-size: 100%;
  margin: $text-margin-top 0 0 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: $header-margin-top 0 0 0;
}

h1, h2 {
  font-size: 160%;
  font-weight: bold;
  line-height: $headers-line-height;
  color: $articles-header-color;
}

h2 {
  font-size: 150%;
}

.sec-header-style {
  font-size: 180%;
}

h3, h4 {
  font-size: 125%;
  font-weight: bold;
  color: $articles-header-color;
}

h5, h6 {
  font-size: 112%;
  font-weight: bold;
  color: $articles-header-color;
}

// all h4 and h5 shows as block with background-color
h4 {
  background-color: $titles-bg-color;
  line-height: 40px;
  text-transform: uppercase;
  color: $titles-text-color;
  font-family: $sec-font-family;
  padding-left: $mobile-side-padding*2;
  @include linear-background-to-right($titles-bg-color, 1, 0, 50%, $main-text-color, 1, 120%)
}

a {
  &:hover {
    > h4 {
      @include linear-background-to-right($articles-header-color, 1, 0, 50%, $main-text-color, 1, 120%)
    }
  }
}

h5 {
  background-color: $articles-header-color;
  line-height: 30px;
  text-transform: uppercase;
  color: $titles-text-color;
  font-family: $sec-font-family;
  padding: 0 $mobile-side-padding;
  font-size: 100%;
}

a {
  &:hover {
    > h5 {
      background-color: $titles-bg-color;
    }
  }
}

a {
  color: $articles-header-color;
  font-weight: bold;
  &:hover {
    color: $titles-bg-color;
    text-decoration: none;
  }
}

ul {
  margin-top: 10px;
  li {
    list-style-type: disc;
    font-size: 100%;
  }
}

.wrapper {
  @extend .clearfix;
  max-width: $project-width;
  margin: 0 auto;
}

.sprites {
  background-image: url(../images/sprites.png);
}

.logo {
  background-image: $logo;
  display: block;
  width: 500px;
  height: 56px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.clear {
  clear: both;
}

.social-media {
  a {
    display: block;
    float: left;
    margin-right: 5px;
    height: 32px;
    width: 32px;
    @extend .sprites;
    i {
      color: $titles-text-color;
      opacity: 0.85;
      position: relative;
      font-size: 25px;
      margin: 3px;
    }
    &:last-child {
      margin-right: 0;
    }
    &.subscribe {
      background-image: none;
      background-color: $cornered-btn-color;
      width: 32px;
      @include after-corner(30px);
      @include before-corner-shadow(30px);
      margin-right: 4px;
      i {
        font-size: 24px;
        margin: 3px 0 0 0px;
        padding-left: 7px;
      }
    }
    &.signin {
      background-image: none;
      width: 32px;
      background-color: $titles-bg-color;
      background-position: -90px -100px;
      @include before-corner-shadow(30px);

    }
    &.twitter {
      background-position: 0 0;
    }
    &.facebook {
      background-position: -50px 0;
    }
    &.youtube {
      background-position: -100px 0;
    }
    &.linkedin {
      background-position: -150px 0;
    }
    &.google {
      background-position: -200px 0;
    }
    &:hover {
      opacity: 0.75;
    }
    &.info {
      width: auto;
      background-image: none;
      line-height: 32px;
      background-color: $titles-bg-color;
      color: $titles-text-color;
      padding: 0 $screen-side-padding 0 3px;
      position: relative;
      margin-left: 5px;
      i {
        float: left;
        margin-right: 5px;
        &.fa-external-link-square {
          top: 2px;
          font-size: 22px;
        }
      }
      &:after, span {
        @include corners;
        background-color: inherit;
        right: -5px;
        width: 10px;
      }
      span {
        right: -10px;
        opacity: 0.5;
        &:last-child {
          right: -15px;
        }
      }
      &:hover {
        opacity: 1;
        background-color: $articles-header-color;
      }
    }
  }
}

.navbar {
  a {
    text-transform: capitalize;
    font-family: $sec-font-family;
  }
}

.dropdown-div {
  opacity: 0;
  overflow: hidden;
  position: fixed;
  left: 0;
  margin-top: 0;
  max-height: 0;
  z-index: -100;
  width: 100%;
  -webkit-transition: opacity 0.1s, max-height 0.1s; /* Safari */
  transition: opacity 0.1s, max-height 0.1s;
  a {
    text-transform: none;
  }
  li {
    list-style-type: none;
  }
}

.sub-menu {
  ul {
    border-top: 1px solid $articles-header-color;
    li {
      border-bottom: 1px solid $articles-header-color;
      padding: 3px 0 3px 7px;
      &:hover {
        background-color: $articles-header-color;
        a {
          color: $titles-text-color;
        }
      }
    }
  }
}

.container, .row {
  margin: 0;
  padding: 0;
  width: 100% !important;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
  padding: 0;
}

.guide-entry {
  .col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/*
*
*	END Main Rules
*
*/

/*
*
*	START Header Rules
*
*/

#header {
  @include navbar-spaces-fix;
  background-color: $page-content-bg-color;
  position: relative;
  .wrapper {
    padding: 8px 0 0 0;
  }
  .social-media {
    display: none;
  }
  .logo {
    margin: 0 0 0 10px;
  }
  .navbar {
    margin-top: 8px;
    background-color: $page-content-bg-color;
    .navbar-toggle {
      position: absolute;
      float: none;
      right: 0;
      margin-right: 20px;
      padding: 7px 11px;
      margin-top: -59px;
      margin-bottom: 0;
      background-color: #FFFFFF;
      background-image: none;
      border: 1px solid $articles-header-color !important;
      border-radius: 0;
      i {
        font-size: 26px;
        color: $cornered-btn-color;
      }
    }
    #navbar {
      a {
        color: $main-text-color;
        border-top: 1px solid $articles-header-color;
        font-size: $text-font-size;
        font-weight: normal;
        &:active {
          color: #666;
        }
      }
    }
  }
}

#news-section {
  @include navbar-spaces-fix;
  background-color: $titles-bg-color;
  .navbar {
    background-color: $titles-bg-color;
    .navbar-header {
      .navbar-toggle {
        float: left;
        padding: 0;
        border: 0 !important;
        border-radius: 0;
        margin: 8px 15px 2px;
        i {
          font-size: 28px;
          color: $titles-text-color;
        }
        &:hover {
          background-color: transparent !important;
        }
      }
      h2 {
        margin: 0;
        line-height: 40px;
        color: $titles-text-color;
        font-weight: normal;
        font-family: $sec-font-family;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
    #news-navbar {
      position: relative;
      > ul {
        > li {
          > a {
            font-size: 112%;
            color: $titles-text-color;
            font-weight: bold;
            border-top: 1px solid $titles-text-color;
          }
          .dropdown-div {
            .wrapper {
              background-color: $footer-bg-color;
              padding: $screen-side-padding $mobile-side-padding;
              .row {
                position: relative;
                .bottom-link {
                  display: block;
                  margin: 0;
                  right: 0;
                  position: absolute;
                  width: 61.93%;
                  bottom: 0;
                  z-index: 10;
                }
              }

            }
            .col-md-9 {
              border-right: 0;
            }
            .article-img-box {
              background-color: $footer-bg-color;
            }
            .article-img {
              h3 {
                font-size: 20px;
              }
              p {
                opacity: 1;
                bottom: 0;
                font-size: 24px;
                font-weight: bold;
                line-height: 100%;
              }
            }
            .double-cols .col-md-6:last-child, .col-md-3 {
              padding-bottom: 50px;
            }
            .tiled-articles {
              .article {
                padding: $mobile-side-padding 0;
                border-bottom: 1px solid $articles-header-color;
                a {
                  margin-top: 0;
                }
                h2 {
                  font-size: 120%;
                  margin-top: 0;
                }
                &:first-child {
                  padding-top: 0;
                }
                &:last-child {
                  border-bottom: 0;
                }
              }
            }
            .advert {
              a {
                margin: 0;
              }
            }
          }
        }
      }
    }
  }
}

/*
*
*	END Header Rules
*
*/

/*
*
*	START Body Rules
*
*/

.main-left-col {
  > .text-box {
    &:first-child {
      padding-top: 0;
    }
  }
  & > .row:first-child {
    & .text-box {
      padding-top: 0;
    }
  }
}

.text-box {
  + .text-box {
    padding-top: 0;
  }
  padding: 20px 0;
  li {
    margin-left: 20px;
    padding-top: 10px;
    span {
      color: $titles-bg-color;
      font-weight: bold;
    }
  }
  hr {
    border-color: $articles-header-color;
  }
  img {
    display: block;
    margin-top: 20px;
  }
  & > *:first-child {
    margin-top: 0;
  }

}

.e-learning {
  h1, h2 {
    + div {
      margin-top: 20px;
      & + div.col-sm-3 {
        margin-top: 10px;
      }
    }
  }
  .social-media {
    margin-top: 25px !important;
  }
  .info {
    text-transform: uppercase;
    font-size: 88%;
    font-family: $sec-font-family;
  }
}

.sponsors {
  margin-top: 20px;
  max-width: 410px;
  border: 1px solid $articles-header-color;
  > div {
    height: 160px;
    padding: 10px 20px;
    + div {
      border-top: 1px solid $articles-header-color;
    }
    span {
      display: block;
      height: 100%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }
}

.sec-color {
  color: $titles-bg-color;
  font-weight: bold;
}

.sec-header-style {
  line-height: 100% !important;
}

.page-content {
  padding: 20px $mobile-side-padding;
  background-color: $page-content-bg-color;
}

.title-block {
  background-color: $titles-bg-color;
  padding: 7px 15px;
  * {
    text-transform: uppercase;
    font-family: $sec-font-family;
    font-size: 100%;
    line-height: 120%;
    color: $titles-text-color;
    font-weight: normal;
    margin: 0;
  }
}

.articles {
  .row {
    .article {
      padding-top: $mobile-side-padding;
      padding-bottom: $mobile-side-padding;
      @extend .clearfix;
    }
    &:last-child {
      @include grey-border-bottom;
    }
  }
}

.grey-border {
  @include grey-border-bottom;
}

.article {
  @extend .clearfix;
}

.link-title {
  display: block;
  position: relative;
  @extend .title-block;
  @include after-corner-title;
  &:hover {
    background-color: $articles-header-color;
  }
}

.cornered-button {
  margin: $screen-side-padding 0;
  display: inline-block;
  background-color: $titles-bg-color;
  color: $titles-text-color;
  padding: 0 $mobile-side-padding;
  position: relative;
  &.blue-button {
    background-color: $articles-header-color;
    &:hover {
      background-color: $titles-bg-color;
    }
  }
  h3 {
    color: $titles-text-color;
    margin: 0 0 0 $mobile-side-padding/2;
    display: inline-block;
    line-height: 30px;
    text-transform: uppercase;
    font-size: 88%;
    font-weight: 500;
  }
  i {
    color: $titles-text-color;
    float: left;
    margin-right: 5px;
    line-height: 29px;
    font-size: 125%;
    margin-top: 1px;
    opacity: 0.75;
  }
  &:after, span {
    @include corners;
    background-color: inherit;
    right: -5px;
    width: 12px;
  }
  span {
    right: -10px;
    opacity: 0.5;
    &:last-child {
      right: -15px;
    }
  }
  &:hover {
    opacity: 1;
    background-color: $articles-header-color;
  }
}

.cornered-button-lg {
  h3 {
    line-height: 40px;
    font-size: 100%;
  }
  i {
    line-height: 39px;
    font-size: 175%;
  }
}

.article-img-box {
  position: relative;
  background-color: $page-content-bg-color;
  .link-title {
    position: absolute;
    z-index: 10;
  }
}

.article-img {
  @extend .clearfix;
  display: block;
  max-width: 390px;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  img {
    display: none;
  }
  > div {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    p {
      position: absolute;
      display: block;
      bottom: -60px;
      padding: 15px 45px 15px 15px;
      line-height: 130%;
      background-color: rgba($article-hover-text, 0.7);
      color: $titles-text-color;
      font-weight: normal;
      opacity: 0;
      -webkit-transition: opacity 0.5s, bottom 0.5s; /* Safari */
      transition: opacity 0.5s, bottom 0.5s;
    }
    div {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      background-color: $article-hover-text;
      &:before {
        @include corners;
        background-color: inherit;
        left: -4px;
        width: 10px;
      }
      i {
        position: relative;
        color: $titles-text-color;
        padding: 5px;
        font-size: 22px;
        z-index: 2;
      }
    }
  }
  &:after {
    padding-top: 72%;
    display: block;
    content: '';
  }
}

#imageGallery {
  li {
    list-style: none !important;
  }
  .article-img {
    cursor: pointer;
  }
}

.tiled-articles {
  @extend .clearfix;
  .article {
    .article-img {
      &:after {
        padding-top: 22.5%;
      }
    }
    .article-tile {
      display: none;
      height: 75px;
      width: 105px;
      background-size: cover;
      background-position: center center;
    }
    h2 {
      font-size: 150%;
    }
  }
}

.classes {
  .row {
    .article {
      @include grey-border-bottom;
    }
    &:last-child {
      div {
        &:last-child {
          .article {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.top-stories {
  .row {
    border: 0 !important;
  }
  .tiled-articles {
    &:last-child {
      > div {
        &:last-child {
          .article {
            border-bottom: 0;
          }
        }
      }
    }
  }
  .article {
    .article-img {
      &:after {
        padding-top: 22.5%;
      }
    }
    .article-tile {
      display: block;
      float: right;
      height: 75px !important;
      width: 105px !important;
      margin: 0 0 0 $mobile-side-padding !important;
    }
    h2 {
      font-size: 112%;
      line-height: 120%;
      font-family: $main-font-family;
      margin-top: 0;
      a {
        font-weight: 500;
      }
    }
    padding: $mobile-side-padding 0 !important;
    border-bottom: 1px solid $articles-header-color;
  }
}

.date-comment {
  font-weight: 500;
  font-size: 88%;
  color: $articles-date-color;
  line-height: 20px;
  display: block;
  strong {
    font-weight: 500;
    position: relative;
    margin-right: 22px;
    display: inline-block;
    &:after {
      @include corners;
      right: -15px;
      width: 4px;
      background-color: rgba($articles-date-color, 0.5);
    }
  }
  span {
    display: inline-block;
    height: 100%;
    position: relative;
    margin-left: 8px;
    padding: 0 8px;
    background-color: $articles-header-color;
    color: $titles-text-color;
    &:before {
      @include corners;
      left: -3px;
      width: 6px;
      background-color: inherit;
    }
    &:after {
      @include corners;
      right: -3px;
      width: 6px;
      background-color: inherit;
    }
  }
  a {
    font-weight: 500;
    &:hover {
      span {
        background-color: $titles-bg-color;
      }
    }
  }
}

.underlined {
  padding-bottom: 15px;
  border-bottom: 1px solid $articles-header-color;
}

.date {
  font-weight: 500;
  font-size: 88%;
  color: $articles-date-color;
  line-height: 20px;
}

.advert {
  margin: 0 auto;
  text-align: center;

  /*&.leader_board{
      height: 90px !important;
      width: 728px !important;
      iframe{
          display: block;
          margin: 0;
          height: 90px !important;
          width: 728px !important;
      }
  }
  &.mpu{
      height: 250px !important;
      width: 300px !important;
      margin: 20px 0 20px 0;
      iframe{
          display: block;
          margin: 0;
          height: 250px !important;
          width: 300px !important;
      }
  }
  &.three_by_one{
      height: 100px !important;
      width: 300px !important;
      margin: 20px 0 20px 0;
      iframe{
          display: block;
          margin: 0;
          height: 100px !important;
          width: 300px !important;
      }
  }*/
  /*a {
      margin: $screen-side-padding auto;
      background-size: cover;
      background-position: center center;
  }
  .large {
      display: block;
      max-width: 727px;
      &:after {
          padding-top: 12.3%;
          display: block;
          content: '';
      }
  }
  .medium {
      display: block;
      max-width: 300px;
      &:after {
          padding-top: 83.3%;
          display: block;
          content: '';
      }
  }*/
}

.page-footer-text {
  padding-bottom: $mobile-side-padding;
  p {
    font-size: 88%;
  }
}

.main-left-col {
  > div {
    &:last-child {
      @include grey-border-bottom;
    }
  }
  .article-page {
    &:last-child {
      border-bottom: 0;
    }
  }
}

.main-right-col {
  padding-top: $mobile-side-padding;
  .search-bar {
    display: none;
  }
}

.events {
  position: relative;
  background-color: $events-bg-color;
  @extend .clearfix;
  margin-top: $header-margin-top;
  .link-title {
    padding: 8px 15px 8px 20px;
    h3 {
      font-size: 125%;
      font-weight: bold;
    }
  }
  .events-list {
    clear: both;
    > div {
      padding: $mobile-side-padding;
      border-bottom: 1px solid #FFFFFF;
      p {
        margin: 0;
        a {
          color: $main-text-color;
          font-weight: normal;
          &:hover {
            color: $titles-bg-color;
          }
        }
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
  a {
    &:last-child {
      h5 {
        text-align: right;
        margin: 0;
      }
    }
  }
}

.date-location {
  margin-top: $mobile-side-padding/2 !important;
  @extend .clearfix;
  color: $events-date-color;
  font-weight: bold;
  line-height: 110%;
  span {
    float: right;
    padding-left: 10px;
  }
}

.journal-tile {
  display: block;
  height: 182px;
  width: 127px;
}

.journals {
  .journal-tile {
    float: left;
    margin-right: $mobile-side-padding;
  }
  > div {
    @extend .clearfix;
    padding: $screen-side-padding 0;
    p {
      margin-top: 0;
    }
  }
  h3 {
    margin-top: 0;
  }
  .date {
    margin-top: 30px;
  }
}

.bulletin {
  form {
    padding: $mobile-side-padding 0 $screen-side-padding 0;
  }
}

.custom-form, .custom-form-2 {
  .form-control {
    border-radius: 0;
    margin-top: 10px;
    padding: 0 15px;
    font-size: 20px;
    height: 40px;
    border: 1px solid $main-text-color;
    &:focus {
      border-color: $main-text-color;
      outline: 0;
      @include box-shadow(inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px $titles-bg-color);
    }
    &::-webkit-input-placeholder {
      color: #666666;
      font-weight: normal;
    }
    &:-moz-placeholder { /* older Firefox*/
      color: #666666;
      font-weight: normal;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #666666;
      font-weight: normal;
    }
    &:-ms-input-placeholder {
      color: #666666;
      font-weight: normal;
    }
  }
  textarea {
    height: 140px !important;
    padding-top: 5px !important;
    resize: none;
  }
  button {
    margin-top: 10px;
    &.btn {
      border: 0;
      padding: 6px 20px;
      font-size: 20px;
      border-radius: 0;
      text-transform: uppercase;
    }
    &.btn-default {
      color: $titles-text-color;
      background-color: transparent;
      @include linear-background-to-right($articles-header-color, 1, 0, 50%, $main-text-color, 1, 120%);
      &:hover {
        @include linear-background-to-right($titles-bg-color, 1, 0, 50%, $main-text-color, 1, 120%);
      }
    }
  }
  label {
    color: #666666;
    font-weight: normal;
    font-size: 125%;
    margin-top: 10px;
  }
}

.custom-form-2 {
  padding-bottom: $mobile-side-padding;
  .cornered {
    @include before-corner-input;
  }
  .form-control {
    margin-top: 0;
    height: 30px;
    font-size: 100%;
    border: 2px solid $articles-header-color;
    padding: 0px 20px;
    &:focus {
      border-color: $articles-header-color;
      outline: 0;
      box-shadow: none;
    }
    &::-webkit-input-placeholder {
      color: $articles-header-color;
    }
    &:-moz-placeholder { /* older Firefox*/
      color: $articles-header-color;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: $articles-header-color;
    }
    &:-ms-input-placeholder {
      color: $articles-header-color;
    }
  }
  button {
    margin-top: 0;
    height: 30px;
    position: relative;
    &.btn {
      padding: 1px 15px 0;
    }
    &.btn-default {
      background: none;
      background-color: $articles-header-color;
      &:hover {
        background: none;
        background-color: $articles-header-color;
      }
    }
    i {
      position: absolute;
      top: 4px;
      right: 5px;
      font-size: 20px;
      &.fa-sign-in {
        top: 5px;
        right: 7px;
      }
    }
  }
}

.form-inline {
  margin-top: 5px;
  label {
    text-transform: uppercase;
    color: $articles-header-color;
    display: block;
  }
  .form-control {
    margin-top: 5px;
  }
  button {
    margin-top: 5px;
  }
  .create-forgotten {
    font-size: 88%;
    margin-top: $mobile-side-padding;
    font-weight: 500;
    text-align: right;
    color: $articles-header-color;
    a {
      font-weight: 500;
    }
  }
}

.custom-label {
  .form-control {
    margin-top: 4px;
    max-width: 450px;
  }
}

.floated-form {
  float: right;
  margin-top: 10px;
}

.company-search {
  margin-top: $screen-side-padding;
}

.top-page-header {
  display: inline-block;
  margin-top: 0;
  position: relative;
  text-transform: uppercase;
  @extend .clearfix;
  float: none;
  h1 {
    margin: 0 25px 0 0;
    display: inline-block;
    background-color: $titles-bg-color;
    padding: $mobile-side-padding $mobile-side-padding*1.5;
    font-weight: normal;
    color: $titles-text-color;
    font-family: $sec-font-family;
    position: relative;
    font-size: 150%;
    &:after {
      @include corners;
      width: 25px;
      background-color: inherit;
      right: -12px;
    }
  }
  &:hover {
    h1 {
      background-color: $articles-header-color;
    }
  }
}

.list-page-header {
  padding-top: $mobile-side-padding;
}

.pagination {
  position: relative;
  li {
    a {
      background-color: $articles-header-color;
      color: $titles-text-color;
      border: 1px solid $page-content-bg-color;
      position: relative;
      border-bottom: 0;
      padding: 0px 11px;
      line-height: 30px;
      font-size: 88%;
      font-weight: 500;
      &:hover {
        background-color: $titles-bg-color;
        color: $titles-text-color;
      }
    }
    &:first-child {
      a {
        margin-left: 20px;
        padding-left: 8px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        border-left: 0;
        text-transform: uppercase;
        &:before, span {
          @include corners;
          background-color: inherit;
          width: 10px;
          left: -5px;
        }
        span {
          left: -10px;
          opacity: 0.5;
          &:first-child {
            left: -15px;
          }
        }
      }
    }
    &:last-child {
      a {
        margin-right: 20px;
        padding-right: 8px;
        border-bottom-right-radius: 0px;
        border-top-right-radius: 0px;
        text-transform: uppercase;
        bordert-right: 0;
        &:after, span {
          @include corners;
          background-color: inherit;
          width: 10px;
          right: -5px;
        }
        span {
          right: -10px;
          opacity: 0.5;
          &:last-child {
            right: -15px;
          }
        }
      }
    }
    &.active {
      a {
        background-color: $titles-bg-color;
        border-color: $page-content-bg-color;
        &:hover {
          background-color: $titles-bg-color;
          border-color: $page-content-bg-color;
        }
      }
    }
    &.disabled {
      a {
        background-color: #CCCCCC;
        border-color: $page-content-bg-color;
        color: $titles-text-color;
        &:hover {
          background-color: #CCCCCC;
          border-color: $page-content-bg-color;
          color: $titles-text-color;
        }
      }
    }
  }
}

.article-page {
  .article {
    h1 {
      border: 0;
    }
  }
  .date-comment {
    clear: left;
    margin-top: $screen-side-padding;
  }

}

.credits {
  color: $events-date-color;
  font-weight: 300;
  font-style: italic;
}

.read-comments {
  @extend .clearfix;
  margin: $screen-side-padding 0;
  a {
    display: inline-block;
    position: relative;
    background-color: $articles-header-color;
    color: $titles-text-color;
    padding: 0 30px 0 $mobile-side-padding*1.5;
    font-size: 125%;
    font-family: $sec-font-family;
    line-height: 40px;
    text-transform: uppercase;
    font-weight: normal;
    span {
      background-color: $articles-header-opacity-eq;
      line-height: 40px;
      position: absolute;
      color: $titles-text-color;
      font-weight: bold;
      z-index: 10;
      right: -20px;
      padding: 0 $mobile-side-padding;
      &:before, &:after {
        @include corners;
        background-color: inherit;
        width: 12px;
      }
      &:before {
        left: -6px;
      }
      &:after {
        right: -6px;
      }
    }
    &:hover {
      background-color: $titles-bg-color;
      span {
        background-color: $titles-opacity-bg-eq;
      }
    }
  }
}

#request_more_info {
  .content {
    background-color: $titles-bg-color;
    padding: 5px 20px 5px 20px;
    color: #ffffff;
  }
  h2 {
    &.request_more_info {
      @extend .clearfix;
      margin: $screen-side-padding 0;
      display: inline-block;
      position: relative;
      background-color: $articles-header-color;
      color: $titles-text-color;
      padding: 0 30px 0 $mobile-side-padding*1.5;
      font-size: 125%;
      font-family: $sec-font-family;
      line-height: 40px;
      text-transform: uppercase;
      font-weight: normal;
      span {
        background-color: $articles-header-opacity-eq;
        line-height: 40px;
        position: absolute;
        color: $titles-text-color;
        font-weight: bold;
        z-index: 10;
        right: -20px;
        padding: 0 $mobile-side-padding;
        &:before, &:after {
          @include corners;
          background-color: inherit;
          width: 12px;
        }
        &:before {
          left: -6px;
        }
        &:after {
          right: -6px;
        }
      }
    }
  }
}

.page-content {
  .social-media {
    margin-top: $mobile-side-padding;
    a {
      margin-top: 5px;
    }
    div {
      &:last-child {
        clear: left;
      }
    }
  }
}

.companies-list {
  margin: $mobile-side-padding 0 0 0;
  &.lg {
    margin-top: $screen-side-padding;
  }
  li {
    border-top: 1px solid $titles-bg-color;
    list-style-type: none;
    a {
      padding: 5px $mobile-side-padding*3.5 5px $mobile-side-padding;
      line-height: 140%;
      display: block;
      position: relative;
      text-transform: capitalize;
      span {
        color: #AAAAAA;
      }
      &:after {
        content: '\f18e';
        font-family: 'fontAwesome';
        display: inline-block;
        position: absolute;
        right: $mobile-side-padding;
        top: 50%;
        margin-top: -11px;
        font-size: 24px;
        font-weight: normal;
        color: #DDDDDD;
      }
      &:hover {
        color: $titles-text-color;
        background-color: $titles-bg-color;
        span {
          color: $titles-text-color;
        }
      }
    }
    &:last-child {
      border-bottom: 1px solid $titles-bg-color;
    }
  }
}

.directory {
  .form-group {
    margin-bottom: 0;
    width: 100%;
  }
  a {
    h2 {
      span {
        color: #AAAAAA;
        font-size: 75%;
      }
    }
  }
  .row {
    > p {
      &:first-child {
        margin-top: $header-margin-top;
      }
    }
  }
}

.guide-entry{
  .form-group {
    margin-bottom: 15px;
    width: 100%;
  }
}

.company-info-tiles, .contact-us-tiles {
  margin-top: $mobile-side-padding;
  > div {
    padding: $screen-side-padding 0;
    border-top: 1px solid $titles-bg-color;
    > a {
      display: block;
    }
    h2 {
      font-size: 125%;
      text-transform: capitalize;
    }
    p {
      font-size: 88%;
      margin: 5px 0 0 5px;
    }
    ul {
      margin-bottom: 0;
      li {
        font-size: 88%;
        padding-top: $mobile-side-padding/2;
        padding-left: $mobile-side-padding*3;
        position: relative;
        list-style-type: none;
        p, a {
          line-height: 130%;
          font-weight: normal;
          color: $main-text-color;
          margin-top: 0;
        }
        a {
          &:hover {
            color: $titles-bg-color;
          }
        }
        i {
          position: absolute;
          top: 7px;
          left: 5px;
          display: block;
          width: 18px;
          line-height: 18px;
          text-align: center;
          color: $articles-header-color;
          font-size: 18px;
        }
      }
    }
  }
}

.contact-us-tiles {
  > div {
    border-top: 0;
    ul {
      & + p {
        margin-top: 10px;
      }
    }
  }
  hr {
    border-color: $titles-bg-color;
    margin-bottom: 0;
    margin-top: 15px;
    & + h2 {
      margin-top: 15px;
    }
  }
  a {
    span {
      color: $articles-header-color;
      font-weight: bold;
    }
    &:hover {
      span {
        color: $titles-bg-color;
      }
    }
  }
  .cornered-header {
    margin-bottom: 10px;
  }
}

.cornered-header {
  margin: 0 25px 0 0;
  display: inline-block;
  background-color: $titles-bg-color;
  text-transform: uppercase !important;
  padding: $mobile-side-padding*1.2 $mobile-side-padding*1.5 !important;
  font-weight: normal;
  color: $titles-text-color;
  font-family: $sec-font-family;
  position: relative;
  font-size: 160% !important;
  &.inverted {
    background-color: $articles-header-color;
  }
  &:after {
    @include corners;
    width: 25px;
    background-color: inherit;
    right: -12px;
  }
  & + .questions {
    margin-top: 20px;
    border-top: 1px solid $titles-bg-color;
  }
}

.company-page {
  .company-info-tiles {
    margin-top: 0;
    > div {
      border-top: 0;
      padding-bottom: 0;
      h2 {
        font-size: 200%;
      }
    }
  }
  .double-companies-list {
    h3 {
      margin-top: $screen-side-padding;
      font-size: 112%;
      color: $titles-bg-color;
    }
  }
}

.double-companies-list {
  @include grey-border-bottom;
  .companies-list {
    margin-bottom: 0;
  }
  > div {
    &:last-child {
      .companies-list {
        margin-top: 0;
        li {
          &:first-child {
            border-top: 0;
          }
        }
      }
    }
  }
}

.breadcrumb {
  clear: both;
  background-color: transparent;
  margin-bottom: $mobile-side-padding;
  padding-left: 0;
  > li {
    font-size: 88%;
    color: $articles-header-color;
    &.active {
      color: $articles-header-color;
      font-weight: bold;
    }
    a {
      &:hover {
        color: $titles-bg-color;
      }
    }
    + li {
      &:before {
        content: "> ";
        padding: 0 5px;
        color: inherit;
      }
    }
  }
}

.free-to-read {
  position: relative;
  margin: $screen-side-padding 0;
  max-width: 404px;
  width: 100%;
  background-color: $titles-bg-color;
  padding: $mobile-side-padding;
  > div {
    color: $titles-text-color;
    > a {
      &:first-child {
        float: right;
        display: block;
        margin-left: $screen-side-padding;
        img {
          border: 2px solid $page-content-bg-color;
          width: 100px;
        }
      }
    }
  }
  h2 {
    margin-top: 0;
    color: $titles-text-color;
    text-transform: Uppercase;
  }
  p {
    line-height: 120%;
    padding-bottom: 50px;
  }
  .cornered-button {
    position: absolute;
    bottom: $mobile-side-padding;
    background-color: $page-content-bg-color;
    margin-bottom: 0;
    h3 {
      color: $titles-bg-color;
      font-weight: bold;
    }
    i {
      color: $articles-header-color;
    }
    &:hover {
      h3 {
        color: $articles-header-color;
      }
    }
  }
}

.extra-padding {
  padding-top: $mobile-side-padding !important;
}

.main-left-col {
  .top-stories {
    display: none;
  }
}

.list-bottom-padding {
  padding-bottom: $screen-side-padding*1.5 !important;
}

/*iframe {
	margin: $screen-side-padding 0 $mobile-side-padding*1.5;
	width: 100% !important;
	height: 300px !important;
}*/

#carousel {
  a {
    display: block;
  }
  img {
    width: 100%;
    float: none !important;
    margin: 15px 0 0 0 !important;
  }
}

#form-ctrl-buttons {
  margin-top: 10px;
  text-align: center;
  .active {
    @include linear-background-to-right($titles-bg-color, 1, 0, 50%, $main-text-color, 1, 120%);
  }
  button {
    border: 0;
    display: inline-block;
    width: 145px;
    line-height: 40px;
    margin-top: 10px;
    font-size: 100%;
    color: $titles-text-color;
    text-transform: uppercase;
    font-family: $main-font-family;
    font-weight: normal;
    text-align: center;
    @include linear-background-to-right($articles-header-color, 1, 0, 50%, $main-text-color, 1, 120%);
    &:hover {
      @include linear-background-to-right($titles-bg-color, 1, 0, 50%, $main-text-color, 1, 120%)
    }
  }
}

.padding-bottom-fix {
  padding-bottom: 0 !important;
}

/*
*
*	END Body Rules
*
*/

/*
*
*	START Footer Rules
*
*/

#footer {
  @extend .clearfix;
  @extend .wrapper;
  min-height: 300px;
  background-color: $footer-bg-color;
  @include navbar-spaces-fix;
  overflow: hidden;
  nav {
    position: relative;
    z-index: 10;
    -webkit-box-shadow: 0px -1px 16px 14px rgba($events-date-color, 0.5);
    -moz-box-shadow: 0px -1px 16px 14px rgba($events-date-color, 0.5);
    box-shadow: 0px -1px 16px 14px rgba($events-date-color, 0.5);
    &.navbar {
      background-color: $titles-bg-color;
      ul {
        padding: 10px 0 10px $mobile-side-padding;
        li {
          float: left;
          padding: 0 $screen-side-padding 0 0;
          &:after {
            content: '/';
            position: absolute;
            right: 0;
            margin-right: 6px;
            top: 0;
            color: $titles-text-color;
            margin-top: 5px;
          }
          &:last-child {
            padding-right: $mobile-side-padding;
            &:after {
              display: none;
            }
          }
          a {
            color: $titles-text-color;
            font-size: $text-font-size;
            font-weight: normal;
            padding: 0px;
            line-height: 30px;
            &:hover {
              color: rgba($titles-text-color, 0.6);
            }
          }
        }
      }
    }
  }
  .wrapper {
    padding: 30px $mobile-side-padding 40px $mobile-side-padding;
    .footer-list {
      ul {
        text-align: center;
        text-transform: capitalize;
        li {
          list-style-type: none;
          a {
            font-family: $sec-font-family;
            font-size: 112%;
            line-height: 120%;
          }
        }
      }
    }
    .contact-address {
      text-align: center;
      p {
        margin: 0;
        font-size: 88%;
        line-height: 130%;
        margin-top: $screen-side-padding;
        span {
          color: $titles-bg-color;
          font-weight: bold;
          padding-right: $mobile-side-padding;
        }
      }
    }
    .copyright {
      @extend .clearfix;
      margin-top: 20px;
      text-align: center;
      p {
        margin: 0;
        font-size: 75%;
        line-height: 160%;
        font-weight: 300;
        a {
          font-weight: 300;
          color: $main-text-color;
          &:first-child {
            margin-right: 15px;
          }
          &:hover {
            color: $titles-bg-color;
          }
        }
      }
    }
  }
}

.advertising {
  > img {
    display: none;
  }
  .text-box {
    & > img {
      display: none;
    }
  }
  .cornered-button {
    margin-bottom: 0 !important;
    min-width: 270px;
  }
}

.subscribe-buttons-fix {
  padding-top: 0;
  h2 {
    margin-top: 30px;
  }
  h3 {
    margin-top: 30px;
  }
  h3 + .cornered-button {
    margin-top: 30px;
  }
  .cornered-button {
    margin-bottom: 5px !important;
    margin-top: 5px;
    h3 {
      margin-top: 0;
    }
  }
  > img {
    max-width: 30%;
  }
}

#download-app {
  @include linear-background-to-bottom($page-content-bg-color, 1, 0, #E6E6E6, 1, 100%);
  padding-bottom: 10px;
  img {
    display: none;
    width: 100%;
    margin: 0;
  }
  h3, h4, p {
    background-image: none;
    margin: 0;
  }
  h3 {
    margin-top: 30px;
    color: $titles-bg-color;
    text-align: center;
    font-size: 200%;
    line-height: 100%;
  }
  h4 {
    color: #666666;
    text-align: right;
    font-size: 100%;
    line-height: 100%;
    text-transform: none;
  }
  p {
    margin-top: 40px;
    text-align: center;
  }
  .app-links {
    margin-top: 15px;
    background-color: #C7C7C7;
    padding: 10px 0 6px;
    border-radius: 0 10px 10px 0;
    text-align: center;
    a {
      display: inline-block;
      width: 134px;
      height: 45px;
      background-color: #000000;
      font-size: 0;
    }
  }
}

.app-box {
  padding-bottom: 0 !important;
}

.features {
  margin-top: 20px;
  margin-bottom: 0;
  border-top: 1px solid $titles-bg-color;
  li {
    list-style-type: none;
    position: relative;
    margin-left: 0;
    padding: 8px 0 8px 50px;
    border-bottom: 1px solid $titles-bg-color;
    font-weight: bold;
    color: $articles-header-color;
    &:before {
      content: '\f058';
      font-family: 'fontAwesome';
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 10px;
      font-size: 26px;
      color: $titles-bg-color;
      font-weight: normal;
    }
  }
}

.questions {
  border-bottom: 1px solid $titles-bg-color;
  padding-bottom: 20px;
  h3, p {
    padding-left: 50px;
    position: relative;
  }
  h3 {
    &:before {
      content: '\f059';
      font-family: 'fontAwesome';
      position: absolute;
      top: 0;
      margin-top: -6px;
      left: 10px;
      font-size: 30px;
      color: $articles-header-color;
      font-weight: normal;
    }
    & + p {
      margin-top: 20px;
      &:before {
        content: '\f058';
        font-family: 'fontAwesome';
        position: absolute;
        top: 0;
        left: 10px;
        font-size: 30px;
        color: $titles-bg-color;
        font-weight: normal;
      }
    }
  }

}

/*
*
*	END Footer Rules
*
*/

#body_text {
  span, p, div {
    font-family: $main-font-family !important;
    font-size: 100% !important;
    line-height: $text-line-height !important;
  }

  ul, li{
    margin: 10px;
  }
}

#buyers-guide-packages-table {
  &.table {
    tbody > tr > td:nth-child(n+2) {
      vertical-align: middle;
      text-align: center;
    }

    td > label {
      margin: 0;
    }

    .featured {
      border-left: 1px $titles-bg-color solid;
      border-right: 1px $titles-bg-color solid;
    }
    .package-tabs {

      td, th {
        vertical-align: top !important;
      }
      .small {
        background-color: $titles-bg-color;
        display: block;
        width: 100%;
        color: #fff;
        font-size: 60%;
        padding: 3px;
        margin: 5px 0 5px 0;
      }
    }
    .package-heading {
      th {
        vertical-align: middle !important;
        p {
          margin: 0;
          font-size: 90%;
        }
      }
      h3 {
        color: #fff;
        margin: 0;
        font-size: 110%;
      }

      background-color: $body-background-color;
      color: #fff;

    }
  }
}

.form-group.required{
  .control-label:after {
    content:" *";
    color:red;
  }
}

@font-face {
  font-family: 'Glyphicons Halflings';
  src: url('/assets/fonts/glyphicons-halflings-regular.eot');
  src: url('/assets/fonts/glyphicons-halflings-regular.eot?#iefix') format('embedded-opentype'), url('/assets/fonts/glyphicons-halflings-regular.woff') format('woff'), url('/assets/fonts/glyphicons-halflings-regular.ttf') format('truetype'), url('/assets/fonts/glyphicons-halflings-regular.svg#glyphicons-halflingsregular') format('svg');
}

// Guide Entry

.true{
  color: $titles-bg-color;
}

.false{
  color: $body-background-color;
}

.dataTables_paginate{
  li{
    span{
      &.glyphicon{
        display: none;
      }
    }
  }
}

.overview{
  .panel{
    .row{
      margin: 0 0 5px 0;
    }
  }

  .guide-list{
    ul{
      margin-left: 10px;
      list-style: none;
    }
    li {
      padding-left: 1.3em;
      list-style: none;
    }
    li:before {
      content: "\f00c"; /* FontAwesome Unicode */
      font-family: FontAwesome;
      display: inline-block;
      margin-left: -1.3em; /* same as padding-left set on li */
      width: 1.3em; /* same as padding-left set on li */
      color: $titles-bg-color;
    }
  }
}

//Advertise
#advertise{
  .grey-border{
    margin-bottom: 15px;
    h1{
      font-size: 150%;
      margin: 0;
    }
    img{
      float: left;
      margin: 0 10px 10px 0;
    }
    .strong{
      font-weight: bold;
    }
  }
}

//END Advertise

// Import responsive rules
@import "responsive.scss";