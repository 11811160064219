/*
*
*   Environmental Technology color patterns;
*
*/

$body-background-color: #878f85;
$body-background-color-shadow: #303630;
$titles-bg-color: #59A428;
$titles-opacity-bg-eq: #ACD193;
$articles-header-opacity-eq: #A5AE9E;
$articles-header-color: #4B5E3E;
$articles-date-color: #4B5E3E; 
$article-hover-text: #4B5E3E;
$navbar-hover-color: #4B5E3E;
$cornered-btn-color: #A5AE9E;
$events-date-color: #4B5E3E;
$events-bg-color: #DEEDD4;
$footer-bg-color: #CDE4BE;
$article-separator: #DBDFD8;
$logo: url(/assets/images/logos/sta.jpg);
