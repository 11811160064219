/*
*
*   Custom mixins for corners and background gradients
*
*/

/* fixes margins, borders and paddings from bootstraps navbars */ 
@mixin navbar-spaces-fix {
    .navbar {
	min-height: 0;
	margin-bottom: 0px;   
	border: 0;  
    }
    .navbar-nav, .navbar-collapse, .navbar-header {
	margin: 0 !important;
    }
    .container-fluid, .navbar-collapse  {
	padding-right: 0;
	padding-left: 0;
    }
    .navbar-collapse {
        border: 0;
    }
}

/* background gradient from top to bottom*/
@mixin linear-background-to-bottom($color1, $opacity1, $break1, $color2, $opacity2, $break2 ) {
    background: -webkit-linear-gradient(top,rgba($color1,$opacity1) $break1, rgba($color2,$opacity2) $break2); /*Safari 5.1-6*/
    background: -moz-linear-gradient(bottom,rgba($color1,$opacity1) $break1, rgba($color2,$opacity2) $break2);; /*Fx 3.6-15*/
    background: -o-linear-gradient(bottom,rgba($color1,$opacity1) $break1, rgba($color2,$opacity2) $break2); /*Opera 11.1-12*/
    background: linear-gradient(to bottom,rgba($color1,$opacity1) $break1, rgba($color2,$opacity2) $break2); /*Standard*/ 
}

/* background gradient from left to right */
@mixin linear-background-to-right($color1, $opacity1, $break1,$break2, $color2, $opacity2, $break3 ) {
    background: -webkit-linear-gradient(left,rgba($color1,$opacity1) $break1, rgba($color1,$opacity1) $break2, rgba($color2,$opacity2) $break3); /*Safari 5.1-6*/
    background: -moz-linear-gradient(right,rgba($color1,$opacity1) $break1, rgba($color1,$opacity1) $break2, rgba($color2,$opacity2) $break3); /*Fx 3.6-15*/
    background: -o-linear-gradient(right,rgba($color1,$opacity1) $break1, rgba($color1,$opacity1) $break2, rgba($color2,$opacity2) $break3); /*Opera 11.1-12*/
    background: linear-gradient(to right,rgba($color1,$opacity1) $break1, rgba($color1,$opacity1) $break2, rgba($color2,$opacity2) $break3); /*Standard*/ 
}

/* cornered corner for links of a ul, inside a div - requires line-height and the background-color when hover */
@mixin navbar-cornered($line-height, $hover-color ) {
    > ul {                	
	> li {
	    &:first-child {
		> a {
		    &:before {
			display: none;
		    }
		}
	    } 
	    &:last-child {
		> a {
		    &:after {
			display: none;
		    }
		}
	    }
	    &:hover {
		> a {
		    background-color: $hover-color;	
		    &:after {
			background-color: $hover-color;
		    }
		    &:before {
			background-color: $hover-color;
		    }
		}
	    }	    
	    > a {                   
		line-height: $line-height;         
		position: static;         
		margin-right: $line-height/4;
		padding-right: $line-height/4;
		padding-left: $line-height/4;		
		&:before {
		    content: '';                       
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    left: ($line-height/8)*(-1);
		    width: $line-height/4;                          
		    -webkit-transform: skew(-15deg);
		    -moz-transform: skew(-15deg);
		    -ms-transform: skew(-15deg);
		    transform: skew(-15deg);    
		}
		&:after {
		    content: '';                       
		    position: absolute;
		    top: 0;
		    bottom: 0;
		    right: $line-height/8;
		    width: $line-height/4;                         
		    -webkit-transform: skew(-15deg);
		    -moz-transform: skew(-15deg);
		    -ms-transform: skew(-15deg);
		    transform: skew(-15deg);    
		}   
	    }
	}
    }
}

/* simple corners. left-right, background-color and width need to be added at the css. */
@mixin corners {
    content: '';
    position: absolute;			
    top: 0;
    bottom: 0;        	
    -webkit-transform: skew(-15deg);
    -moz-transform: skew(-15deg);
    -ms-transform: skew(-15deg);
    transform: skew(-15deg);   
}

/* after corner mixins start*/
@mixin after-corner($parent-height, $background-color: inherit) {    
    margin-right: ($parent-height/3);
    position: relative;
    &:after {
	content: '';
	position: absolute;			
	top: 0;
	bottom: 0;
	right: ($parent-height/6)*(-1);
	width: ($parent-height/3);   	
	background-color: $background-color;		
	-webkit-transform: skew(-15deg);
	-moz-transform: skew(-15deg);
	-ms-transform: skew(-15deg);
	transform: skew(-15deg);    
    }
}

/* after corner titles */
@mixin after-corner-title {
    margin: 0 18px 0 0;	
    display: inline-block; 
    padding-right: 20px;		
    &:after {
	content: '';
	position: absolute;			
	top: 0;
	bottom: 0;
	right: -10px;
	width: 20px;
	background-color: inherit;		
	-webkit-transform: skew(-15deg);
	-moz-transform: skew(-15deg);
	-ms-transform: skew(-15deg);
	transform: skew(-15deg);   
    } 
}

/* before corner shadow mixin starts*/
@mixin before-corner-shadow($parent-height, $background-color: inherit) {
    margin-left: ($parent-height/3)*2;
    position: relative;    
    &:before, span {
	content: '';
	position: absolute;        		
	top: 0;
	bottom: 0;
	left: ($parent-height/6)*(-1); 
	width: ($parent-height/3);           		
	background-color: $background-color;		
	-webkit-transform: skew(-15deg);
	-moz-transform: skew(-15deg);
	-ms-transform: skew(-15deg);
	transform: skew(-15deg);    
    }
    span {
	left: ($parent-height/3)*(-1);
	opacity: 0.5;
	&:first-child {
	    left: ($parent-height/2)*(-1);
	}
    } 
}

@mixin before-corner-input {
    margin-left: 15px;
    position: relative;
    &:before, > span {
	@include corners;
	background-color: $articles-header-color;
	width: 5px;
	left: 0px;
	z-index: 10;
    }
    > span {
	width: 10px;
	left: -5px;			
	background-color: $articles-header-opacity-eq;			
	z-index: 9;
	&:first-child {
	    left: -10px;
	    opacity: 0.5;
	}
    }
}

/* includes grey border */
@mixin grey-border-bottom {
    border-bottom: 4px solid $article-separator;
}