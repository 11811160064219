$project-width: 1200px;
$text-font-size: 16px;
$text-line-height: 130%;
$headers-line-height: 100%;  
$text-margin-top: 12px;   
$header-margin-top: 20px;  
$mobile-side-padding: 10px;   
$screen-side-padding: 20px;  
$page-content-bg-color: #FFFFFF;     
$main-text-color: #000000; 
$main-font-family: 'Roboto';    
$sec-font-family: 'Roboto condensed'; 
$titles-text-color: #FFFFFF;

$icon-font-path: '/assets/fonts/';
$fa-font-path:   '/assets/fonts';